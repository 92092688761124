.App {
	font-family: system-ui;
	font-weight: 400;
	font-size: 1.25rem;
	cursor: default;
	min-width: 1000px;
}

.nav {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 3rem;
	background-color: #e5f6ff;
	color: #282c34;
	align-content: center;
}

.section {
	display: flex;
	min-height: 110vh;
	padding: 2rem;
	text-align: center;
	opacity: 0.6;
	color: #282c34;
	transition-duration: 0.5s;
}

@media screen and (max-width: 850px) {
	.section {
		display: flex;
		min-height: 110vh;
		padding: 2rem;
		text-align: center;
		opacity: 1;
		transition-duration: 0.5s;
	}	
}

.section:hover {
	opacity: 1;
	scale: 102%;
}

.content {
	display: flex;
	justify-content: center;
	flex-direction: column;
	width: 100%;
	height: 100%;
}

.container {
	padding: 2rem;
	margin: 2rem;
	border-radius: 1rem;
	box-shadow: #57908b18 0px 7px 29px 0px;
	text-align: left;
	color: #282c34;
	font-size: 1rem;
}

.proyectbox {
	display: flex;
	flex-direction: row;
	padding: 2rem;
	margin: 2rem;
	text-align: left;
	color: #282c34;
	font-size: 1rem;
	opacity: 0.6;
	transition-duration: 0.5s;
	text-decoration: none;
}

.proyectbox:hover {
	opacity: 1;
	scale: 102%;
}

.preview {
	margin-right: 2rem;
	width: 20rem;
	max-height: 20rem;
	opacity: 0.7;
	border-radius: 1rem;
}

.button {
	opacity: 0.6;
	transition: 0.5s;
	text-decoration: none;
	color: #282c34;
	padding: 0.5rem;
	border-radius: 1rem;
	border: none;
	box-shadow: #57908b18 0px 0px 0px 3px;
	background: local;
}

.button:hover {
	opacity: 1;
	scale: 105%;
	background-color: #57908b18;
	cursor: pointer;
}

.logo {
	width: 4rem;
	max-height: 4rem;
	opacity: 0.7;
}

.logo:hover {
	opacity: 1;
	cursor: pointer;
}

.alert {
	position: fixed;
	z-index: 9999;
	padding: 1rem;
	bottom: 0;
	right: 0;
	margin: 2rem;
	border-radius: 1rem;
	box-shadow: #1fff9618 0px 7px 29px 0px;
	background-color: #ccfde6;
}